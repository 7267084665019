<script>
import { ref } from "vue";
import { mapActions } from "vuex";

import documentManagerUT from "@/components/widgets/projects/documentManagerUT";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../../app.config";
import moment from 'moment'
import serverConfig from '@/helpers/config';
import Swal from "sweetalert2";
import axios from 'axios';
import {
    required,
    helpers
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  page: {
    title: "Gestione Documentazione Progetto",
    meta: [{ name: "description", content: appConfig.description }],
  },
  setup() {
    let files = ref([]);
    moment.locale('it')
  
    let tk = 'Bearer '+localStorage.getItem('tk')

    return { files, tk, v$: useVuelidate() };
  },
  data() {
    return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        showMainCustomer:false,
        showMainProgect:true,
        showMainSystems:false,
        showMainSurvey:false,
        showMainAdmin:false,
        showMainDocuments:true,
        showAmmProgect:false,
        showTechProgect:false,
        showAdvancedProgect:false,
        title: "Gestione documenti per controllo qualità progetto",
        data:{
          source:'',
          customerCategory:'',
          systemCategory:'',
          systemSubCategory:'',
          fiscalBenefit:'',
          surveyDetails:{
              date:moment.unix(),
              dateStr:moment().format('YYYY-MM-DD'), 
              status: '', 
              response:'',
              note:'',
              createdBy:{}, 
          },
          accountingDetails:{
              date:moment.unix(),
              dateStr:moment().format('YYYY-MM-DD'), 
              status: '', 
              response:'',
              note:'',
              createdBy:{}, 
          },            
        },
        optionsFiscalBenefit:[],
        optionsFSPrivate:[
          { value: '', label: 'Seleziona beneficio fiscale' },
          { value: 'conto_termico', label: 'Conto termico' },
          { value: 'detrazione_fiscale', label: 'Detrazione fiscale' },
        ],
        optionsFSBusiness:[
          { value: '', label: 'Seleziona beneficio fiscale' },
          { value: 'credito_imposta', label: "Credito d'imposta" },
          { value: 'sconto_in_fattura', label: 'Sconto in fattura' },
        ],
        optionsFSPA:[
          { value: 'non_previsto', label: 'Non previsto' },
        ],
        optionsSystems:[],
        optionsSystemsACS:[
          { value: '', label: 'Seleziona sottocategoria ACS' },
          { value: 'scalda_acqua_in_pdc', label: 'Scalda acqua in pompa di calore' },
          { value: 'solare_termico', label: 'Solare Termico' },
        ],
        optionsSystemsClima:[
          { value: '', label: 'Seleziona sottocategoria Climatizzazione' },
          { value: 'area_area', label: "Area area" },
          { value: 'area_acqua', label: 'Area acqua' },
          { value: 'rap', label: 'R.A.P' },
          { value: 'termo_idraulica', label: 'Termo idraulica' },
          { value: 'vrv', label: 'VRV' },
        ],
        optionsSystemsED:[
          { value: '', label: 'Seleziona sottocategoria Edilizia' },
          { value: 'coibentazione_mc', label: "Coibentazione Manti Ceramic" },
          { value: 'coibentazione_eps', label: 'Coibentazione EPS' },
          { value: 'impiantistica_idrica', label: 'Impiantistica idrica' },
          { value: 'ristrutturazioni', label: 'Ristrutturazioni' },
        ],
        optionsSystemsEL:[
          { value: '', label: 'Seleziona sottocategoria Energia elettrica' },
          { value: 'FTV', label: "Fotovoltaico" },
          { value: 'sistemi_di_accumulo', label: 'Sistemi di accumulo' },
          { value: 'colonnine_di_rc', label: 'Colonnine di ricarica' },
          { value: 'impiantistica_elettrica', label: 'Impiantistica elettrica' },
        ],
        checkCustomerID:false,
        checkSystem:false,
      n_required:0,
      n_true:0,
      r:0,
      project:{
        tipo:'commessa', // Tipologia di lavoro => 'Commessa', prevalentement
        code:'code',
        fonteAcquisizione:'',
        tipologiaCommessa:'', // privato/azienda
        beneficioFiscale:'',
        sopralluogo:'',
        dettagliSopralluogo:{},
        registrazioneAmministrativa:'no',
        dettagliRegistrazioneAmministrativa:{},
        modPagamento:'',
        subappalto:'no',
        dettagliSubappalto: 'no',
        ftv:'no',
        dettagliFtv:{},
        suap:'no',
        autorizzazioneSuap:'no',
        praticaEnel:'no',
        dettagliPraticaEnel:{},
        cantiere:'no',
        cantiereID: '',   
        dettagliCantiere:{},
        progettazione: 'no',
        dettagliProgettazione:{},
        parent: '',
        name: '',
        descriptions: '',
        status:'init',
        start:0,
        end:0,
        start_tmp:0,
        end_tmp:0,
        year:0,
        month:0,
        day:0,
        user:{},
      },
      customerDetails:{        
        CodStato: 'IT',
        code:'',
        ragSoc:'',
        partitaIva:'',
        codiceFiscale:'',
        codeDest:'',
        email:'',
        pec:'',
        telefono:'',
        CodiceAlternativo_1:'',
        CodiceAlternativo_2:'',
        indirizzo:'',
        citta:'',
        provincia:'',
        cap:'',
        Stato:'',
        TipoSoggetto:'',
        date_joined:'',
      },
      opzioneBeneficio:[],
      worksite:{
            projectID: this.projectID,
            area:"",
            status:"init", // 'init'/'inprogress'/'pending'/'completed'/'problem'
            name:"",
            description:"",
            type:"",
            country:"",
            zipCode:"",
            city:"",
            address:"",
            address_number:"",
            additional_info_to_address:"",
            cordinate_system:"",
            longitude:"",
            latitude:"",
            year: new Date().getFullYear(),
            dateString:"",
            dateTmp:0,
        
            tasks:[],
            createdBy:this.$store.state.auth.currentUser,
            versions_history:{},
            assignedTo:[],
            startStr: "", 
            endStr: "",
            start: moment.unix(),
            end: "",
            start_tmp: moment.unix(),
            end_tmp: "",
            color: "",
            icon: "",
            url: "",
            geolocal:"",
            
            statusClass: "info",
            priority: "low",
            priorityClass: "success",
      },
      items: [
        {
          text: "Elenco progetti",
          href: "/projects/list-view",
        },
        {
          text: "Dettaglio Progetto",
          href: `/projects/detail/${this.$route.params.id}`,
        },
        {
          text: "Documentazione",
          active: true,
        },
      ],
      value: ["C#", "HTML", "CSS"],
      value3: ["Private"],
      value4: ["Designing"],
      value5: ["Ellen Smith"],
      value1: ["Inprogress"],
      value2: ["High"],
      editor: ClassicEditor,
      editorData:
        "",
      content: "",


    };
  },
    validations: {
      data: {
        source: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        customerCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        systemCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        systemSubCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        fiscalBenefit: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
      },
    },
  mounted() {
    this.getItem()
  },
  beforeMount(){
      if (this.$route.name!='project-dms'){
        this.title = 'Piano di Qualità - S2 - Elenco documenti'
        this.items = [
          {
            text: "Elenco Commesse",
            href: "/quality-control/list-view",
          },
          {
            text: "Dettaglio Commessa",
            href: `/quality-control/detail/${this.$route.params.id}`,
           },
          {
            text: "Elenco documenti",
            active: true,
          },
        ];
    }
  },
  methods: {
    ...mapActions('layout', ['changeLayoutType', ]),
    axiosInterceptor(){
      axios.interceptors.response.use(
            (response) => {
                
                return response;
            },
            (error) => {
                if (error.response && error.response.status === 401) {
                  Swal.fire({
                    title:"Accesso non autorizzato",
                    text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:3500,
                  })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }
                    return Promise.reject('Unauthorized access');
                } else if (error.response && error.response.status  === 404) {
                    Swal.fire({
                      title:"Nessun risultato trovato",
                      text:"Mi spiace, nessun risultato trovato",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('The server cannot find the requested resource');
                }  else if (error.response && error.response.status  === 412) {
                  Swal.fire({
                    title:"Credenziali mancanti",
                    text:"Inserisci delle credenziali valide, per favore.",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              } else if (error.response && error.response.status  === 423) {
                    Swal.fire({
                      title:"Risorsa non accessibile",
                      text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:3000,
                    })
                    this.$router.push('/')             
                    return Promise.reject('The server cannot find the requested resource');
                }
                else if (error.response && error.response.status  === 500) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Internal Server Error');
                }
                else if (error.response && error.response.status  === 502) {
                    Swal.fire({
                      title:"Errore Server",
                      text:"Mi spiace, ma riscontriamo degli errori sul server.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('Bad Gateway');
                }
                else if (error.response && error.response.status  === 503) {
                  Swal.fire({
                    title:"Servizio non disponibile",
                    text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Service Unavailable');
                } else {
                  console.log(error.response.status )
                  Swal.fire({
                    title:"Errore imprevisto",
                    text:"Ops! si è verificato un problema...riprova, per favore.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  
                }

            }
          );
    },
    convertTmpToDate(val){
        return moment.unix(val).format('LLL')
    },
    filterOptions(val){
        switch(val){
          case "azienda":
            this.optionsFiscalBenefit = this.optionsFSBusiness
            break;
          case "privato":
            this.optionsFiscalBenefit = this.optionsFSPrivate
            break;
          case "pubblico":
            this.optionsFiscalBenefit = this.optionsFSPA
            break;
        }        
    },    
    filterOptionsSystem(val){
      
        switch(val){
          case "acqua_calda_sanitaria":
            this.optionsSystems = this.optionsSystemsACS
            break;
          case "climatizzazione":
            this.optionsSystems = this.optionsSystemsClima
            break;
          case "edilizia":
            this.optionsSystems = this.optionsSystemsED
            break;
          case "energia_elettrica":
            this.optionsSystems = this.optionsSystemsEL
            break;
        }   
         
    },    
    deleteRecord(ele) {
      ele.target.parentElement.parentElement.remove();
    },
    cambiaOpzioneBeneficio(){
        let privato = [
          {"value":"sconto-fattura","text":"Sconto in fattura"},
          {"value":"conto-termico","text":"Conto Termico"},
          {"value":"nessuno","text":"Nessuno"},
        ]
        let azienda = [
          {"value":"credito-imposta","text":"Credito d'imposta"},
          {"value":"sconto-fattura","text":"Sconto in fattura"},
          {"value":"nessuno","text":"Nessuno"},
        ]    
       
      if (this.project.tipologiaCommessa =='privato'){
        this.opzioneBeneficio = privato
      } else {
        this.opzioneBeneficio = azienda
      }   
    },
    submitForm(){
     
      document.getElementById("genDataForm").submit();
    },
    getParent(){
      this.getItem()
    },
    checkDocuments(array){
      if (array){
        let count = array.length
        let checkTrue = array.filter(function(el){
          return el.exist == true
        })
        
        if (count > checkTrue.length ){
          this.r = count - checkTrue.length
        } 
        this.n_required = count
        this.n_true = checkTrue

        return true
      } else {
        return false
      }

    },
    getItem() {
      this.data = {}
      this.axiosInterceptor()
      axios.get(`${this.UrlServer}projects/${this.$route.params.id}`, {headers: { authorization:this.tk}}).then((response) => {
          if (response.data) {
              this.data = response.data[0]
              if (!this.data.accountingDetails.contract){
                this.data.accountingDetails.contract = false
              }
              if(!this.data.customerDetails){
                this.data.customerDetails = this.customerDetails
              }

              if (response.data[0].customerCategory){
                  this.filterOptions(response.data[0].customerCategory)
                  this.filterOptionsSystem(response.data[0].systemCategory)

                  if (!response.data[0].customerID){
                      this.checkCustomerID = false
                      //this.showMainCustomer = true
                  } else {
                      this.checkCustomerID = true
                      //this.showMainCustomer = false                    
                  }
                  if (!response.data[0].systems || response.data[0].systems.length == 0){
                      this.checkSystem = false
                      //this.showMainSystems = true
                  } else {
                      this.checkSystem = true
                      //this.showMainSystems = false                    
                  }
                  if (!response.data[0].surveyDetails){
                    this.data.surveyDetails = {
                      date:moment.unix(),
                      dateStr:moment().format('YYYY-MM-DD'), 
                      status: '', 
                      response:'',
                      note:'',
                      createdBy:{},                       
                    }
                  }
                  if (!response.data[0].accountingDetails){
                    this.data.accountingDetails = {
                      date:moment.unix(),
                      dateStr:moment().format('YYYY-MM-DD'), 
                      status: '', 
                      response:'',
                      note:'',
                      createdBy:{},                       
                    }
                  }
                  if (!response.data[0].documentsRequired){
                    this.data.documentsRequired = []
                  }                  
              }
              
          }        
          
      })
    },
    init(){
      this.axiosInterceptor()
      axios.post(`${this.UrlServer}worksites/register`, this.worksite, {headers: { authorization:this.tk}}).then((response) => {
        if(response.data) {
          this.getItem()
          Swal.fire( this.$t("t-msg-worksite-tab-created-title") , this.$t("t-msg-worksite-tab-created-content"), "success");
        }
      })
    },
    getDocs() {
      this.axiosInterceptor()
        axios.get(`${this.UrlServer}settings/all/documents/list/${this.data.systemSubCategory}`, {headers: { authorization:this.tk}}).then((response) => {
          if (response.data) {
            this.data.documentsRequired = response.data
            this.updateItem()
          }        
          
      })
    },
    updateItem(){
       // this.submitted = true;
        this.v$.$touch();
        this.data.createdBy = this.datastore.auth.currentUser
        this.axiosInterceptor()
        axios.put(`${this.UrlServer}projects/${this.$route.params.id}`, this.data, {headers: { authorization:this.tk}} )
          .then(()=>{
            
          })
      },
    updateDoc(tag){
      let val = document.getElementById(tag).value
      console.log(val)

    },
    updateW(){
     this.axiosInterceptor()
     axios.put(`${this.UrlServer}worksites/${this.data.worksite[0]._id}`, this.data.worksite[0], {headers: { authorization:this.tk}})
     .then(() => {
     })
   }, 
   getParentProject(){
    this.getItem()
   },
  },
  components: {
    documentManagerUT,
    Layout,
    PageHeader,
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1" >
            <template v-if="data">
                <span class="mdi mdi-24px mdi-account-details pr-5 "></span>
                <template v-if="data.customerDetails">{{ data.customerDetails.ragSoc }}</template>
                 - Localizzazione: 
                 <template v-if="data.worksite!=null">
                     <template v-if="data.worksite.length>0">
                        {{ data.worksite[0].address }} {{ data.worksite[0].city }}
                     </template>
                </template>
            </template>
            </h4>
            <div class="flex-shrink-0">
                <template v-if="data">
                    Tipo impianto: 
                   <strong> {{ data.systemSubCategory }}</strong>
                </template>
            </div>
          </div>
        </div>
      </div>
    </div>     
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
           
            <div class="card-title mb-0 flex-grow-1">

                <div class="row">
                  <div class="col-lg-12 col-md-6">
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="mdi mdi-24px mdi-archive-arrow-up-outline pr-5 "></span>
                        <template v-if="data.systemCategory && data.systemCategory!=''">
                            <template v-if="checkDocuments(data.documentsRequired)">
                              <template v-if="r==0">
                                <span class="mdi mdi-24px mdi-check pr-5 "></span>
                              </template>
                              <template v-else>
                                <span class="mdi mdi-24px mdi-alert pr-5 "></span>
                              </template>
                            </template>
                            <template v-else>
                              <span class="mdi mdi-24px mdi-alert pr-5 "></span>
                            </template>
                        </template>
                        <template v-else>
                          <span class="mdi mdi-24px mdi-alert pr-5 "> Indicare tipologia di impianto</span>
                        </template>
                        <span class="pr-5">Controllo documentazione - Raccolta dati</span>
                        <b-badge variant="danger" class="badge-label" ><i class="mdi mdi-alert-medium"></i> 
                            <strong>Attenzione!</strong> 
                              &nbsp;<span class="pr-2"><template v-if="r==1"> manca ancora </template><template v-else>mancano ancora</template></span>
                            <b>{{r}}</b> <template v-if="r==1"> documento</template><template v-else>documenti</template> da allegare 
                        </b-badge>
                        
                      </div>
                    </div>        
                  </div>   
                  <div class="col-lg-12 col-md-6">
                  </div>   
                </div>
            </div>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
              >

                <label for="showMainDocuments" class="form-label text-muted"
                  >Mostra</label
                >
                <input
                  class="form-check-input code-switcher"
                  type="checkbox"
                  id="showMainDocuments"
                  v-model="showMainDocuments"
                />
              </div>
            </div>
          </div>
          <div class="card-body" v-if="showMainDocuments">
            <div class="row mb-50">
                <div class="col-lg-12 col-md-12">                                                    
                  <div>   
                    <p class="text-muted bold">Documentazione richiesta</p>
                      <p class="text-muted">Il controllo della documentazione prevista dalla pratica viene effettuata anche automaticamente sulla base della documentazione caricata in cartella</p>
                        
                        <div class="form-check  form-check-success mb-3 form-check-inline" v-for="item in data.documentsRequired" :key="item._id">
                          <input class="form-check-input" type="checkbox" :id="item.tag" v-model="item.exist" :ref="item.tag" :value="item.exist" onclick="return false;">
                            <label class="form-check-label" :for="item.tag">
                              {{item.label}}
                            </label>
                        </div>                                                        
                  </div>
                </div>
            </div>
            <div class="mt-50">
              <template v-if="data">
                <template v-if="data.customer!=null">
                  <template v-if="data.customer.length>0">
                    <template v-if="!data.customer[0].nomeFascicolo">
                      <b-alert show variant="danger" class="mb-25">
                        <strong> "Nome Fascicolo" non individuato: </strong> aggiorna l'anagrafica cliente, per favore.
                      </b-alert>
                    </template>

                      <documentManagerUT 
                        :url="`${this.UrlServer}storage/by/project`"
                        :projectID="data._id" 
                        :data="data" 
                        :bucket="data.customer[0].nomeFascicolo + data.year.toString() + '/' + data.systemSubCategory+'/'" 
                        type="doc-raccolta_dati"
                        getTag="raccolta_dati_ftv"
                        refDirectory="raccolta_dati"
                        fileType="multiple" 
                        fileAccept="*" 
                        title="Documentazione impianto"
                        @getParent="getParent"
                       />


                  </template>
                </template>
              </template>
            </div>
          
          </div>
        </div>
      </div>
    </div> 
  </Layout>
</template>
